html,
body {
    height: 100%;
    overflow: hidden;
}

body {
    margin: 0;
    font-family: "Roboto", helvetica, sans-serif;
}

#root {
    height: 100%;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
