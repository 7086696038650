.playback-controls {
    display: grid;
    grid-template-columns: minmax(300px, 1fr) 3fr minmax(250px, 1fr);
    grid-template-rows: auto auto;
    justify-content: center;
    align-items: center;
    background-color: #111;

    .current-track-info {
        grid-row-start: 1;
        grid-row-end: 3;
    }

    .playback-controls-primary {
        grid-row-start: 1;
        grid-row-end: 2;
    }

    .playback-controls-secondary {
        text-align: right;
        padding: 0 32px;
    }

    .playback-controls-primary,
    .playback-controls-secondary {
        margin-top: 16px;
    }

    .playback-progress {
        grid-row-start: 2;
        grid-row-end: 3;
        padding: 0 16px;
    }

    .volume-controls {
        margin-bottom: 8px;
        padding: 8px 40px;
        grid-row-start: 2;
        grid-row-end: 3;
    }

    @media screen and (max-width: 960px) {
        grid-template-columns: 300px 1fr;
        grid-template-rows: auto 1fr;

        .volume-controls {
            display: none;
        }
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: minmax(200px, 1fr) auto;

        .current-track-info {
            grid-row-start: 1;
            grid-row-end: 1;
        }

        .playback-controls-primary {
            grid-row-start: 1;
            grid-row-end: 1;
        }

        .playback-progress {
            grid-column-start: 1;
            grid-column-end: 3;
            margin: 8px 0 4px 0;
        }
    }
}
