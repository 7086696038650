$slider-combined-height: 12px;

.playback-progress {
    display: grid;
    grid-template-columns: auto 1fr auto;
    justify-content: center;
    align-items: center;

    p {
        margin: 0 0 4px 0;
    }

    .track-position-slider-container {
        margin: 0 16px;
    }

    .progress-value {
        font-weight: 500;
        font-size: $slider-combined-height;
    }
}