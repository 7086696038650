$row-hover-color: rgba(255, 255, 255, 0.1);
$primary-color: #FF9C61;

.track-list {
    user-select: none;

    .track-row {
        &:hover {
            background-color: $row-hover-color;
        }
    }

    .track-playing {
        color: $primary-color;

        .MuiTableCell-body {
            color: inherit;
        }
    }

    .track-play-button-cell {
        width: 0;
        padding: 0;
    }

    @media screen and (max-width: 600px) {
        .track-album-cell {
            display: none;
        }

        .track-artist-cell {
            padding-right: 0;
        }

        .MuiTableCell-root {
            font-size: 13px;
        }
    }
}