$skeleton-color: rgba(150, 150, 150, 0.2);
$loader-color: rgba(150, 150, 150, 0.07);

.skeleton-loader {
    display: block;
    position: relative;
    height: 1em;
    width: 100%;
    margin: 0.25em 0;
    background-color: $skeleton-color;
    border-radius: 1em;
    overflow: hidden;

    @keyframes loading {
        from {
            left: -50%;
        }

        to {
            left: 150%;
        }
    }

    .loading-indicator {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50%;
        height: 200%;
        animation: loading 1.5s infinite ease;
        filter: blur(8px);
        background-color: $loader-color;
    }
}

.skeleton-loader-image {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin: 0;
}